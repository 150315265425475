var MosaicSlider = function(slides, cols, rows, options)
{
    options = options || {};

    options.delay = options.delay || 100;
    options.duration = options.duration || 800;
    options.zIndex = options.zIndex || 2;
    options.buttons = options.buttons || $();

    options.auto = options.auto === undefined ? 5000 : options.auto;

    options.animation = options.animation || {
        transition: {transition: "transform, opacity"},
        prev: [
            {transform: 'translateY(0px)', opacity: 1},
            {transform: 'translateY(100%)', opacity: .5}
        ],
        next: [
            {transform: 'translateY(0px)', opacity: 1},
            {transform: 'translateY(0px)', opacity: 1}
        ]
    };
    options.cellIndexer = options.cellIndexer || function(lastIndex, dir) {

        var indices = [], i;
        if(dir) {
            for (i = 0; i <= lastIndex; i++) {
                indices.push(i);
            }
        } else {
            for (i = lastIndex; i >= 0 ; i--) {
                indices.push(i);
            }
        }
        return indices;
    };

    var slider = this;
    var currentSlide = options.index || 0;
    var timeout = undefined;
    var busy = false;
    var cssSupport = getCssSupport();

    function getCssSupport() {
        var domPrefixes = 'Webkit Moz O ms Khtml'.split(' '),
            elm = document.createElement('div');

        if(elm.style.animationName !== undefined ) { return true; }

        for( var i = 0; i < domPrefixes.length; i++ ) {
            if( elm.style[ domPrefixes[i] + 'AnimationName' ] !== undefined ) {
                return true;
            }
        }

        return false;
    }


    this.calculate = function(_cols, _rows) {
        cols = _cols || cols;
        rows = _rows || rows;
        slides.each(function() {
            var slide = $(this);
            var width = slide.width();
            var height = slide.height();
            var cellWidth = Math.ceil(width / cols);
            var cellHeight = Math.ceil(height / rows);
            slide.data('cells', []);
            slide.data('container', $('<div/>'));
            slide.data('container').css({
                width: width,
                left: 0,
                height: height,
                top: 0,
                overflow: 'hidden',
                position: 'absolute'
            });
            slide.prepend(slide.data('container'));
            for (var c = 0; c < rows; c++) {
                for (var r = 0; r < cols; r++) {
                    var cell = $('<div/>');
                    cell.css({
                        overflow: 'hidden',
                        left: r * cellWidth,
                        width: cellWidth,
                        height: cellHeight,
                        top: c * cellHeight,
                        position: 'absolute'
                    });
                    slide.data('container').append(cell);
                    slide.data('cells').push(cell);
                    fillCell(slide, cell, slide.data('container'));
                    cell.css(options.animation.transition);
                }
            }
            slide.data('initial', []);
            slide.children().each(function(index){
                var child = $(this);
                if(child.is(slide.data('container')) || child.data('replace') === undefined) return;
                slide.data('initial').push(child);
                child.hide();
            });
        });
    };

    /**
     * method determines whether the specified cell intersects with the child elements of the slide.
     * if so, it fills the cell with copies of such elements
     */
    var fillCell = function(slide, cell, container) {
        var cellOffset = cell.offset();
        var cellDimension = {w: cell.outerWidth(), h: cell.outerHeight(), t: cellOffset.top, l: cellOffset.left};

        slide.children().each(function(){

            var child = $(this);
            if(child.is(container) || child.data('replace') === undefined) return;


            var width = child.width();
            var height = child.height();

            var dimension = child.data('dimension');

            if(!dimension) {
                var childOffset = child.offset();
                dimension = {
                    w: child.outerWidth(),
                    h: child.outerHeight(),
                    t: childOffset.top,
                    l: childOffset.left
                };
            }
            var test = {
                hor: {
                    t1:cellDimension.l,
                    t2:cellDimension.l + cellDimension.w,
                    o1:dimension.l,
                    o2:dimension.l+dimension.w
                },
                ver: {
                    t1:cellDimension.t,
                    t2:cellDimension.t + cellDimension.h,
                    o1:dimension.t,
                    o2:dimension.t+dimension.h
                }
            };

            if(
                (test.hor.t1 <= test.hor.o1 || test.hor.t1 <= test.hor.o2)
                &&
                (test.hor.t2 >= test.hor.o1 || test.hor.t2 >= test.hor.o2)
                &&
                (test.ver.t1 <= test.ver.o1 || test.ver.t1 <= test.ver.o2)
                &&
                (test.ver.t2 >= test.hor.o1 || test.ver.t2 >= test.hor.o2)
            ) {
                var _child = child.clone(true);
                _child.css({
                    top: test.ver.o1 - test.ver.t1,
                    left: test.hor.o1 - test.hor.t1,
                    bottom: 'auto',
                    margin: 0,
                    padding: 0,
                    right: 'auto',
                    position: 'absolute',
                    width: width,
                    height: height
                });
                cell.append(_child);
            }
        });
    };

    this.calculate();

    slides.each(function(index){
        var slide = $(this);
        if(index === currentSlide) {
            slide.css({zIndex: options.zIndex, opacity: 1});
        } else {
            slide.css({zIndex: 0, opacity: 0});
        }
    });

    this.changeIndex = function(index){

        if(busy) return;
        busy = true;

        if(timeout !== undefined) {
            clearTimeout(timeout);
        }

        var nextSlide = slides.eq(index);
        nextSlide.addClass('active');

        if(!nextSlide.length) return;
        var prevSlide = slides.eq(currentSlide);

        nextSlide.css({zIndex: 1, opacity: 1});
        prevSlide.toggleClass('prev');

        var prevCells = prevSlide.data('cells');
        var nextCells = nextSlide.data('cells');

        var indices = options.cellIndexer(rows*cols-1, index > currentSlide);

        options.buttons.removeClass('active');
        options.buttons.eq(index).addClass('active');

        if(cssSupport) {
            for (var i = 0; i < indices.length; i++) {
                prevCells[indices[i]].css({
                    transitionDelay: options.delay * (i + 1) + 'ms',
                    transitionDuration: options.duration + 'ms'
                });
                nextCells[indices[i]].css({
                    transitionDelay: options.delay * (i + 1) + 'ms',
                    transitionDuration: options.duration + 'ms'
                });
                prevCells[indices[i]].css(options.animation.prev[1]);
                nextCells[indices[i]].css(options.animation.next[1]);
            }
            currentSlide = index;
            setTimeout(function () {
                nextSlide.css({zIndex: options.zIndex, opacity: 1});
                prevSlide.css({zIndex: 0, opacity: 0});
                prevSlide.toggleClass('prev');
                for (i = 0; i < indices.length; i++) {
                    prevCells[indices[i]].css({
                        transitionDelay: '0ms',
                        transitionDuration: '0ms'
                    });
                    nextCells[indices[i]].css({
                        transitionDelay: '0ms',
                        transitionDuration: '0ms'
                    });
                    prevCells[indices[i]].css(options.animation.prev[0]);
                    nextCells[indices[i]].css(options.animation.next[0]);
                }
                if (options.auto) {
                    timeout = setTimeout(function () {
                        slider.changeDir(+1);
                        timeout = undefined;
                    }, options.auto);
                }
                busy = false;

            }, options.delay * i + options.duration);
        } else {
            prevSlide.addClass('prev');
            prevSlide.fadeOut(options.duration, function(){

                prevSlide.fadeIn(0);

                prevSlide.removeClass('prev');

                nextSlide.css('z-index', options.zIndex);
                prevSlide.css('z-index', 0);

                busy = false;
                currentSlide = index;

            });
        }

    };

    /**
     *
     * @param dir - number
     */
    this.changeDir = function(dir) {
        if(dir === undefined) {
            dir = 1;
        }
        var lastIndex = slides.length - 1;
        var index = currentSlide + dir;

        if(index < 0) {
            this.changeIndex(lastIndex);
        } else if(index > lastIndex) {
            this.changeIndex(0);
        } else {
            this.changeIndex(index);
        }
    };

    this.detach = function() {
        slides.each(function(){
            var slide = $(this);
            slide.data('container').remove();
            var initial = slide.data('initial');
            for(var i = 0; i < initial.length; i++) {
                initial[i].show();
            }
        });
    };

    if(options.auto) {
        timeout = setTimeout(function(){
            slider.changeDir(+1);
            timeout = undefined;
        }, options.auto);
    }

    if(options.buttons.length) {
        options.buttons.each(function(index){
            var button = $(this);
            button.click(function(){
                slider.changeIndex(index);
            });
        });
    }
};

